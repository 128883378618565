.centered-text-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home-container {
  position: relative;
  text-align: center;
  color: white;
}
.img-text-div {
  background-color: #f5f5f5;
}
.img-div {
  width: 800px;
  display: flex;
  height: 600px;
  padding: 5%;
}
.vdm-navbar {
  grid-template-columns: max-content 100%;
}
.emailText {
  background-color: #f5f5f5;
}

.registerButton {
  /* background-color: black !important; */
  border-radius: 0.6rem !important;
}
.homeTitle {
  /* font-bold text-[54px]  text-center w-full p-[1.4rem] */
  font-weight: 700;
  font-size: 54px;
  text-align: center;
  width: 100%;
  padding: 1.4rem;
}
.contactUs {
  grid-template-columns: 46% 48%;
}
.grid-auto-2 {
  grid-template-columns: max-content auto;
}

@media (max-width: 620px) {
  .contactUs {
    grid-template-columns: 100%;
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

