@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./_theme_colors";
// @import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
